/* eslint-disable tree-shaking/no-side-effects-in-initialization */
import {
  Array,
  Dictionary,
  Lazy,
  Literal,
  Null,
  Number,
  Optional,
  Record,
  Runtype,
  Static,
  String,
  Tuple,
  Union,
} from "runtypes";

const BBox2D = Tuple(Number, Number, Number, Number);
type BBox2D = Static<typeof BBox2D>;

const BBox3D = Tuple(Number, Number, Number, Number);
type BBox3D = Static<typeof BBox3D>;

const BBox = Union(BBox2D, BBox3D);
type BBox = Static<typeof BBox>;

const GeoJsonObject = Record({
  bbox: Optional(BBox),
});

const Position = Array(Number);
type Position = Static<typeof Position>;

const Point = GeoJsonObject.extend({
  type: Literal("Point"),
  coordinates: Position,
});
type Point = Static<typeof Point>;

const MultiPoint = GeoJsonObject.extend({
  type: Literal("MultiPoint"),
  coordinates: Array(Position),
});
type MultiPoint = Static<typeof MultiPoint>;

const LineString = GeoJsonObject.extend({
  type: Literal("LineString"),
  coordinates: Array(Position),
});
type LineString = Static<typeof LineString>;

const MultiLineString = GeoJsonObject.extend({
  type: Literal("MultiLineString"),
  coordinates: Array(Array(Position)),
});
type MultiLineString = Static<typeof MultiLineString>;

const Polygon = GeoJsonObject.extend({
  type: Literal("Polygon"),
  coordinates: Array(Array(Position)),
});
type Polygon = Static<typeof Polygon>;

const MultiPolygon = GeoJsonObject.extend({
  type: Literal("MultiPolygon"),
  coordinates: Array(Array(Array(Position))),
});
type MultiPolygon = Static<typeof MultiPolygon>;

export const Geometry: Runtype<Geometry> = Lazy(() =>
  Union(
    Point,
    MultiPoint,
    LineString,
    MultiLineString,
    Polygon,
    MultiPolygon,
    GeometryCollection,
  ),
);

export type Geometry =
  | Point
  | MultiPoint
  | LineString
  | MultiLineString
  | Polygon
  | MultiPolygon
  | GeometryCollection;

export const GeometryCollection = Record({
  type: Literal("GeometryCollection"),
  geometries: Array(Geometry),
});

export type GeometryCollection = {
  type: "GeometryCollection";
  geometries: Geometry[];
};

export const Feature = GeoJsonObject.extend({
  type: Literal("Feature"),
  geometry: Geometry,
  id: Optional(Union(String, Number)),
  properties: Dictionary(Union(String, Number, Null), Union(String, Number)),
});
export type Feature = Static<typeof Feature>;

export const FeatureCollection = Record({
  type: Literal("FeatureCollection"),
  features: Array(Feature),
});
export type FeatureCollection = Static<typeof FeatureCollection>;

export const GeoJson = Union(Geometry, Feature, FeatureCollection);
export type GeoJson = Static<typeof GeoJson>;
