import { parse } from "wellknown";

import { MapDatum } from "../layer-classes/MapLayerTypes";

import { Feature, GeoJson, Geometry } from "./geojsonTypes";

export function getNonPointGeoJsonFeatures(
  mapData: readonly MapDatum[],
  column: string,
): readonly Feature[] {
  const features: Feature[] = [];
  for (const datum of mapData) {
    const value = datum[column];
    if (value == null) {
      continue;
    }

    // check if WKT format
    const geoOrNull = value != null ? parse(String(value)) : null;
    if (geoOrNull != null && geoOrNull.type !== "Point") {
      features.push({
        type: "Feature",
        geometry: geoOrNull,
        properties: datum,
      });
      continue;
    }

    try {
      const parsed = JSON.parse(String(value).replaceAll("'", '"'));
      if (Geometry.guard(parsed) && !isPoint(parsed)) {
        features.push({
          type: "Feature",
          geometry: parsed,
          properties: datum,
        });
      } else if (Feature.guard(parsed) && !isPoint(parsed.geometry)) {
        features.push({
          ...parsed,
          properties: {
            ...parsed.properties,
            ...datum,
          },
        });
      }
    } catch (_e) {
      // skip unparsable text
    }
  }
  return features;
}

export function convertGeoJsonToFeatures(geojson: GeoJson): readonly Feature[] {
  if (geojson.type === "Feature" || geojson.type === "FeatureCollection") {
    return (geojson.type === "Feature" ? [geojson] : geojson.features).filter(
      (feature) => !isPoint(feature.geometry),
    );
  } else {
    const geos =
      geojson.type === "GeometryCollection" ? geojson.geometries : [geojson];
    const features: Feature[] = [];
    geos.forEach((geo) => {
      if (!isPoint(geo)) {
        features.push({
          type: "Feature",
          geometry: geo,
          properties: {},
        });
      }
    });
    return features;
  }
}

function isPoint(geo: Geometry): boolean {
  return geo.type === "Point" || geo.type === "MultiPoint";
}
