import React from "react";
import styled from "styled-components";

import { LockIcon } from "../icons/CustomIcons";

const Pill = styled.span`
  ${({ theme }) => theme.pill.VIOLET.css}

  display: flex;
  align-items: center;
  justify-content: center;

  width: 20px;
  height: 20px;
  padding: 0;
`;

export const FeatureGateWrapper = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
`;

export interface FeatureGatePillProps {
  className?: string;
}

export const FeatureGatePill: React.ComponentType<FeatureGatePillProps> =
  React.memo(function FeatureGatePill({ className }) {
    return (
      <Pill className={className}>
        <LockIcon iconSize={12} />
      </Pill>
    );
  });
