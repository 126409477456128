import { FeatureGateSet } from "@hex/common";
import { createContext, useContext } from "react";

// eslint-disable-next-line tree-shaking/no-side-effects-in-initialization -- create context
export const FeatureGateContext = createContext<FeatureGateSet | undefined>(
  undefined,
);
FeatureGateContext.displayName = "FeatureGateContext";

export interface UseFeatureGatesArgs<S extends boolean> {
  /**
   * @default false
   */
  safe?: S;
}

export type UseFeatureGatesResult<S> = S extends false
  ? FeatureGateSet
  : FeatureGateSet | undefined;

export function useFeatureGates<S extends boolean = false>(
  args: UseFeatureGatesArgs<S> = {},
): UseFeatureGatesResult<S> {
  const safe = args.safe ?? false;

  const context = useContext(FeatureGateContext);
  if (context == null && !safe) {
    throw new Error("No feature gates provided");
  }
  return context as UseFeatureGatesResult<S>;
}
