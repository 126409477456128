import { HexMapDatasetType } from "./MapDatasetTypes";

type Dataset = {
  type: HexMapDatasetType;
  title: string;
  url: string;
  fields: readonly DatasetField[];
};

type DatasetField = {
  name: string;
  example: string;
  deprecatedWarning?: string;
};

export const DEPRECATED_US_COUNTIES_CODE_FIELD = "code";
export const COUNTY_NAME_AND_STATE_FIELD = "name_and_state";
export function getHexMapDatasets(): Record<string, Dataset> {
  const mapAssetPath: string | undefined = document?.head.dataset.mapAssetPath;
  return mapAssetPath != null
    ? {
        [HexMapDatasetType.COUNTRIES]: {
          type: HexMapDatasetType.COUNTRIES,
          title: "Countries",
          url: `${mapAssetPath}/countries_v1.json`,
          fields: [
            { name: "name", example: "United States" },
            { name: "postal", example: "US" },
            { name: "iso_a2", example: "US" },
            { name: "iso_a3", example: "USA" },
          ],
        },
        [HexMapDatasetType.US_STATES]: {
          type: HexMapDatasetType.US_STATES,
          title: "US States",
          url: `${mapAssetPath}/us-states_v1.json`,
          fields: [
            { name: "name", example: "California" },
            { name: "two_letter_abbr", example: "CA" },
          ],
        },
        [HexMapDatasetType.US_COUNTIES]: {
          type: HexMapDatasetType.US_COUNTIES,
          title: "US Counties",
          url: `${mapAssetPath}/us-counties_v3.json`,
          fields: [
            {
              name: "name",
              example: "Alameda",
              deprecatedWarning:
                "This field is deprecated as county names are not unique across states. Please use the name_and_state or fips field instead.",
            },
            { name: COUNTY_NAME_AND_STATE_FIELD, example: "Alameda,CA" },
            { name: "fips", example: "01001" },
            {
              name: DEPRECATED_US_COUNTIES_CODE_FIELD,
              example: "001",
              deprecatedWarning:
                "This field is deprecated as it is not unique across states. Please switch to the the fips field instead.",
            },
          ],
        },
      }
    : {};
}
