import { DataBrowserTab, DataConnectionId, HexId } from "@hex/common";
import { useCallback } from "react";

import {
  LocalStorageKeys,
  useLocalStorageSetter,
} from "../../hooks/useLocalStorage.js";
import { useOpenSidebar } from "../../hooks/useOpenSidebar.js";
import { SidebarTab } from "../sidebar/SidebarOptions.js";

export const useOpenDataBrowserSidebar = (): ((
  connectionId?: DataConnectionId | HexId,
  // set this to false if you're calling this from within the sidebar (it's already open)
  openSidebar?: boolean,
) => void) => {
  const openConnectionsSidebar = useOpenSidebar(SidebarTab.DATA_CONNECTIONS);

  const setSchemaBrowserConnectionId = useLocalStorageSetter(
    LocalStorageKeys.SCHEMA_BROWSER_CONNECTION(),
  );
  const setBrowserTab = useLocalStorageSetter(
    LocalStorageKeys.DATA_BROWSER_VIEW(),
  );

  return useCallback(
    (connectionId?: DataConnectionId | HexId, openSidebar: boolean = true) => {
      setSchemaBrowserConnectionId(connectionId);
      setBrowserTab(DataBrowserTab.DATA);
      if (openSidebar) {
        openConnectionsSidebar();
      }
    },
    [openConnectionsSidebar, setBrowserTab, setSchemaBrowserConnectionId],
  );
};
