import { getNormalEnum } from "@hex/common";
import { Literal, Static, Union } from "runtypes";

export const HexMapDatasetTypeLiteral = Union(
  Literal("COUNTRIES"),
  Literal("US_STATES"),
  Literal("US_ZIPCODES"),
  Literal("US_COUNTIES"),
);
export const HexMapDatasetType = getNormalEnum(HexMapDatasetTypeLiteral);
export type HexMapDatasetType = Static<typeof HexMapDatasetTypeLiteral>;

export type MapDatasetStatusStarted = {
  type: "started";
};

export type MapDatasetStatusHeader = {
  type: "header";
  status: number;
  sizeInBytes: number;
};

export type MapDatasetStatusStreaming = {
  type: "streaming";
  completionRate: number; // 0: none, 1: complete
};

export type MapDatasetStatusLoaded = {
  type: "loaded";
};

export type MapDatasetStatusFailed = {
  type: "failed";
  error: Error;
};

export type MapDatasetStatus =
  | MapDatasetStatusStarted
  | MapDatasetStatusHeader
  | MapDatasetStatusStreaming
  | MapDatasetStatusLoaded
  | MapDatasetStatusFailed;
