import {
  DataConnectionType,
  DockerImageRepositoryType,
  ExternalFileIntegrationType,
} from "@hex/common";
import React, { useMemo } from "react";
import { useTheme } from "styled-components";

import { DataConnectionIcons } from "./dataConnectionIcons";

type DataConnectionMinimalIconSize =
  | "small"
  | "default"
  | "large"
  | "extra-large";

export type DataConnectionIconProps = {
  connectionType:
    | DataConnectionType
    | ExternalFileIntegrationType
    | DockerImageRepositoryType;
} & (
  | {
      isMinimal?: true;
      minimalIconSize?: DataConnectionMinimalIconSize;
    }
  | {
      isMinimal?: false;
      minimalIconSize?: DataConnectionMinimalIconSize;
    }
);

const FULL_ICON_DEFAULT_HEIGHT = 40;
const FULL_ICON_DEFAULT_WIDTH = 160;

export const DataConnectionIcon: React.ComponentType<DataConnectionIconProps> =
  React.memo(function DataConnectionIcon({
    connectionType,
    isMinimal = true,
    minimalIconSize = "default",
  }: DataConnectionIconProps) {
    const theme = useTheme();

    const minimalIconDefaultSize = useMemo(() => {
      switch (minimalIconSize) {
        case "small":
          return 12;
        case "default":
          return 16;
        case "large":
          return 20;
        case "extra-large":
          return 36;
        default:
          return 16;
      }
    }, [minimalIconSize]);

    const Logo = isMinimal
      ? theme.useInvertedConnectionIcons
        ? DataConnectionIcons[connectionType]?.minimalInverted
        : DataConnectionIcons[connectionType]?.minimal
      : theme.useInvertedConnectionIcons
        ? DataConnectionIcons[connectionType]?.fullInverted
        : DataConnectionIcons[connectionType]?.full;

    return (
      <>
        {isMinimal
          ? Logo != null && (
              <Logo
                height={minimalIconDefaultSize}
                width={minimalIconDefaultSize}
              />
            )
          : Logo != null && (
              <Logo
                height={FULL_ICON_DEFAULT_HEIGHT}
                width={FULL_ICON_DEFAULT_WIDTH}
              />
            )}
      </>
    );
  });
